import { FC, Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";

import { ProtectedEntry } from "./features/Application";
import { Business, Home, Profile, SignIn, SignUp, Favorites, YandexVerify, Messenger, Chat } from "./pages/lazyPages";
import { Suspense as SuspenseComponent } from "./components/Suspence";
export interface FeatureRoute {
	component: FC;
	path: string;
}

export const router = createBrowserRouter([
	{
		path: "/",
		element: (
			<Suspense fallback={<SuspenseComponent />}>
				<Home />
			</Suspense>
		),
	},
	{
		path: "/signin",
		element: (
			<Suspense fallback={<SuspenseComponent />}>
				<SignIn />
			</Suspense>
		),
	},
	{
		path: "/signup",
		element: (
			<Suspense fallback={<SuspenseComponent />}>
				<SignUp />
			</Suspense>
		),
	},
	{
		path: "/profile",
		element: (
			<Suspense fallback={<SuspenseComponent />}>
				<ProtectedEntry>
					<Profile />
				</ProtectedEntry>
			</Suspense>
		),
	},
	{
		path: "/verify_yid",
		element: (
			<Suspense fallback={<SuspenseComponent />}>
				<YandexVerify />
			</Suspense>
		),
	},
	{
		path: "/business/:id",
		element: (
			<Suspense fallback={<SuspenseComponent />}>
				<Business />
			</Suspense>
		),
	},
	{
		path: "/favorites",
		element: (
			<Suspense fallback={<SuspenseComponent />}>
				<Favorites />
			</Suspense>
		),
	},
	{
		path: "/messenger",
		element: (
			<Suspense fallback={<SuspenseComponent />}>
				<Messenger />
			</Suspense>
		),
	},
	{
		path: "/messenger/:chatId",
		element: (
			<Suspense fallback={<SuspenseComponent />}>
				<Chat />
			</Suspense>
		),
	},
]);
